import {useEffect} from 'react';
import styles from './Home.module.css';
import ProductBanner from '../ProductBanner';
import ReactGa from "react-ga";
import Offer from '../Offer';
import {Heading1, Heading2, Body1, Body2, Heading3} from '../value/Styled';
import MainButton from '../Buttons/MainButton';
import { useTranslation } from 'react-i18next';
import {Grid, Container, Icon, Divider} from 'semantic-ui-react';

const Home = () => {

  useEffect(()=> {
    ReactGa.pageview(window.location.pathname)
  });

  const { t } = useTranslation();

    return (
    <div className={styles.home}>
    <Grid>
      <Grid.Row  columns={2} >
        <Grid.Column className={styles.homeStart} computer={16} mobile={16}>
          <div   style={{ background: 'linear-gradient(rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.5) 100%), url("images/home.jpg")'}}
      className={styles.homeStartBack}/>
        <Heading1>{t('Home.Title')}</Heading1>
          <Heading3>{t('Home.Subtitle')}</Heading3>
          <div className={styles.homeResp}>
          <a href="/company">
            <MainButton  className={styles.homeMainButton}>{t('Company.1')}</MainButton>
          </a>
          <a href="/products">
            <MainButton  className={styles.homeMainButton}>{t('Products.1')}</MainButton>
          </a>
          <a href="/contact">
            <MainButton className={styles.homeMainButton}>{t('Contact.1')}</MainButton>
          </a>
          </div>
          <div className={styles.startHref}>
            <a href="/news">
            <img src="images/logo_UE.svg" alt="logo UE" className={styles.startHrefUE}/>
            </a>
            <a href="/certificates">
              <div className={styles.startCertificates}>
            <img src="certy/ENplus2.png" alt="logo Enplus" className={styles.startHrefEnplus}/>
            <div>
            <img src={"certy/"+ t('Home.FSCPhoto')} alt="logo FSC" className={styles.startHrefFSC}/>
            <Body2 className={styles.FSCbody}>{t('Home.AskFor')}<sup>®</sup>{t('Home.AskFor1')}</Body2>
            </div>
            {/*<img src="certy/PEFC.png" alt="logo PEFC" className={styles.startHrefPEFC}/>*/}
              </div>
            </a>
          </div>
        </Grid.Column>
      </Grid.Row>
      {/*------------------------------Informacje Home------------------------------ */}
      <Grid.Row stretched reversed="computer" columns={2} className={styles.homeRow1}>
        <Grid.Column mobile={16} computer={8} className={styles.homeColumn}>
          <img src='images/z14.jpg' className={`${styles.ImagesCover} ${styles.disappear}`} alt=""/>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8} >
            <section className={styles.homeInfo}>
              <Heading2>{t('Home.MoreInfo')}</Heading2>
        <br/>
              <Body1 className={styles.homeBody}>
              {t('Home.MoreInfoText1')}
        <br/><br/>
              {t('Home.MoreInfoText2')}
              </Body1>
        <br/>
              <a href="/company">
                <MainButton>{t('Shorts.FindOutMore')}</MainButton>
              </a>
            </section>
      </Grid.Column>
    </Grid.Row>


{/*------------------------------Zalety------------------------------ */}
    <Grid.Row stretched columns={2} className={styles.homeRow2}>
        <Grid.Column mobile={16} computer={8} className={styles.homeColumn}>
          <img src='images/z13.jpg' className={styles.ImagesCover} alt=""/>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8} >
          <Container text>
            <section className={styles.homeInfo}>
            
        <br/>
              <Body1 className={styles.homeBodyAdvans}>
              <Icon name='box' size='big'/>
                <br/>
                <br/>
                {t('Home.Pros1')}
              </Body1>
               <br/>
               <Heading2 className={styles.homeDate}>1993</Heading2>
              <Body1 className={styles.homeBodyAdvans}>
                <br/>
                {t('Home.Pros2')}
              </Body1>
              <br/>
              <Body1 className={styles.homeBodyAdvans}>
                <Icon name='group' size='big'/>
                <br/>
                <br/>
                {t('Home.Pros3')}
              </Body1>
            </section>
         </Container>
      </Grid.Column>
    </Grid.Row>
   {/*------------------------------Promocje------------------------------ */}
   {/* <Offer/> */}
   {/*------------------------------Produkty------------------------------ */}

   <Grid.Row className={styles.homeProducts}>
      <Grid.Column width={16} className={styles.homeProductsTitle}>
          <Heading2>
            <b>
            {t('Home.BestProducts')}
            </b>
          </Heading2>
      </Grid.Column>       
        <Grid.Column className={styles.homeProductsImg}   mobile={16} computer={8} style={{ minHeight:  450,
        background: 'linear-gradient(rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0.4) 100%), url("images/pellet1.jpg")', 
        }}>
          <a href="/products--pellet-drzewny">
          <div className={styles.homeProductsContent}>
          <Heading2>
          {t('Products.PelletTitle')}
          </Heading2>
          <Body1 className={styles.homeProductsBody}>
          {t('Home.PelletText')}
          </Body1>
          </div>
          </a>
        </Grid.Column>     <Grid.Column className={styles.homeProductsImg}   mobile={16} computer={8} style={{ minHeight:  450,
        background: 'linear-gradient(rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0.4) 100%), url("images/klej1.jpg")', 
        }}>
          <a href="/products--klejonka">
          <div className={styles.homeProductsContent}>
          <Heading2>
          {t('Products.KlejonkaTitle')}
          </Heading2>
          <Body1 className={styles.homeProductsBody}>
          {t('Home.KlejonkaText')}
          </Body1>
          </div>
          </a>
        </Grid.Column>    
         <Grid.Column className={styles.homeProductsImg}   mobile={16} computer={8} style={{ minHeight:  450,
        background: 'linear-gradient(rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0.4) 100%), url("images/tralka1.jpg")', 
        }}>
          <a href="/products--tralki">
          <div className={styles.homeProductsContent}>
          <Heading2>
          {t('Products.TralkiTitle')}
          </Heading2>
          <Body1 className={styles.homeProductsBody}>
          {t('Home.TralkiText')}
          </Body1>
          </div>
          </a>
        </Grid.Column>   
          <Grid.Column className={styles.homeProductsImg}   mobile={16} computer={8} style={{ minHeight:  450,
        background: 'linear-gradient(rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0.4) 100%), url("images/slup1.jpg")', 
        }}>
          <a href="/products--słupy-schodowe">
          <div className={styles.homeProductsContent}>
          <Heading2>
          {t('Products.SlupyTitle')}
          </Heading2>
          <Body1 className={styles.homeProductsBody}>
          {t('Home.SlupyText')}
         </Body1>
          </div>
          </a>
        </Grid.Column>  
   </Grid.Row>
    {/*------------------------------Oferta-------------------------------- */}
   <ProductBanner/>
   <Divider/>
   {/*------------------------------Dotacje------------------------------ */}
    <Grid.Row>
      <Grid.Column width={16} className={styles.homeMazo}>
        <a href="/news" className={styles.homeMazoA}>
        <img src="images/UE.png" alt="" className={styles.homeMazoImg}/>
        </a>
      </Grid.Column>
    </Grid.Row>
          {/*------------------------------Kontakt------------------------------ */}

    <Grid.Row className={styles.homeContact}>
        <Grid.Column mobile={16} computer={8}>
          <Body1>
          {t('Shorts.Ask')}
            <br/>
            <br/>
          {t('Shorts.Decided')}
            <br/>
            <br/>
          {t('Shorts.MoreInfo')}
          </Body1>
        </Grid.Column> 
        <Divider/>
        <Grid.Column mobile={16} computer={8} className={styles.homeContactInfo}>
          <Heading3>
          {t('Shorts.ContactUs')}
          </Heading3>
          <a href="/contact">
            <MainButton className={styles.homeContactButton}>{t('Contact.1')}</MainButton>
          </a>
        </Grid.Column>
    </Grid.Row>

     
  </Grid>
  </div>
  );}


export default Home